<template>
  <div>
    <keep-alive>
      <router-view/>
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {}
  },
  methods: {},
  // mounted() {
  //   const returnValue = '请注意！在您关闭或离开本站点之前，请确保您的数据已经保存。'
  //   window.onbeforeunload = e =>{
  //     e = e || window.event
  //     if(e){
  //       e.returnValue = returnValue
  //     }
  //     return returnValue
  //   }
  // }
}
</script>

<style>
* {
  font-size: 14px;
}

.page-title {
  font-weight: 520;
  font-size: 1.2rem;
}

.el-form-item {
  margin-bottom: 8px;
}

.el-dialog__body {
  padding: 20px;
}
</style>
