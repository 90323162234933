import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // *** 生产环境建议注释掉此内容
  // {
  //   path: '/',
  //   redirect: '/monitor'
  // },
  //***********
  {
    path: '/monitor',
    component: () => import('../views/Login'),
  },
  {
    path: '/monitor/home',
    component: () => import('../views/Home'),
    children: [
      {
        path: '/',
        redirect: '/monitor/home/welcome',
      },
      {
        path: 'welcome',
        component: () => import('../views/Welcome'),
      },
      {
        path: 'raw-info',
        component: () => import('../views/RawInfo'),
      },
      {
        path: 'raw-sku',
        component: () => import('../views/RawSKU'),
      },
      {
        path: 'raw-up-logs',
        component: () => import('../views/RawUpLogs'),
      },
      {
        path: 'raw-ex-logs',
        component: () => import('../views/RawExLogs'),
      },
      {
        path: 'prod-square-plugin',
        component: () => import('../views/ProdSquarePlugin'),
      },
      {
        path: 'prod-square-sku',
        component: () => import('../views/ProdSquareSku'),
      },
      {
        path: 'prod-square-sku-logs',
        component: () => import('../views/ProdSquareSkuLogs'),
      },
      {
        path: 'prod-square-sku-pv',
        component: () => import('../views/ProdSquareSkuPv'),
      },
      {
        path: 'prod-square-sku-pv-out',
        component: () => import('../views/ProdSquarePvOut'),
      },
      {
        path: 'prod-round-sku',
        component: () => import('../views/ProdRoundSku'),
      },
      {
        path: 'prod-round-sku-logs',
        component: () => import('../views/ProdRoundSkuLogs'),
      },
      {
        path: 'prod-extension-sku',
        component: () => import('../views/ProdExtSku'),
      },
      {
        path: 'prod-extension-sku-logs',
        component: () => import('../views/ProdExtSkuLogs'),
      },
      {
        path: 'other-users',
        component: () => import('../views/Users'),
      },
      {
        path: 'other-custom',
        component: () => import('../views/Custom'),
      },
      {
        path: 'other-source',
        component: () => import('../views/Source'),
      },
      {
        path: 'prod-extension-in',
        component: () => import('../views/ProdEpiIn'),
      },
      {
        path: 'prod-extension-out',
        component: () => import('../views/ProdEpiOut'),
      },
      {
        path: 'prod-extension-in-t',
        component: () => import('../views/ProdEpiInToday'),
      },
      {
        path: 'prod-extension-out-t',
        component: () => import('../views/ProdEpiOutToday'),
      },
      {
        path: 'prod-extension-is-in',
        component: () => import('../views/ProdEpiIsIn'),
      },
      {
        path: 'prod-extension-make',
        component: () => import('../views/ProdEpiMake'),
      },
      {
        path: 'prod-extension-make-t',
        component: () => import('../views/ProdEpiMakeToday'),
      },
      {
        path: 'epi-substrate',
        component: () => import('../views/Substrate'),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
